import toast from "./toast";
const base64 = require('base-64');

import { Readability, isProbablyReaderable } from '@mozilla/readability';

export default (Vue) => {
    return Vue.mixin({
        methods: {
            toast,
            async getFileTypeBlob(url) {
                let res = await this.axios({
                    url: `${process.env.VUE_APP_CORS_URL}${url}`,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                });
                // let ext = res.data.type.split( "/" );
                return res.data.type;
            },
            getParameterByName(name, url = window.location.href) {
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            },
            getDeliveryType() {
                return "share"; // [dynamic, embed, share]
            },
            countDownTimer(defaultCount = 0, interval = 1000){
                let timer = null;
                let rejecter = null;
                let counter = defaultCount
                function count(eachCallback){
                    if(typeof eachCallback !== "function") throw new Error("[countDownTimer] requires a function as an argument")

                    return new Promise((resolve, reject) => {
                        rejecter = reject;
                        timer = setInterval(() => {

                            eachCallback(counter);

                            if(--counter <= 0){
                                clearInterval(timer);
                                counter = defaultCount
                                rejecter = null
                                resolve();
                            }

                        }, interval)
                    })
                }

                count.cancel = function(){
                    clearInterval(timer);
                    if(typeof rejecter === "function") rejecter()
                }

                return count;
            },
            slugify(string){
                return string ? string
                .toLowerCase()
                .replace(/ /g,'-')
                .replace(/[^\w-]+/g,'') : ""
            },
            generateBase64 ( data ) {
                const obj = {
                    platform_customer_id: data.platform_customer_id,
                    experience_id: data.experience_id,
                    experience_type: data.experience_type,
                };
                return base64.encode( JSON.stringify( obj ) );
            },
            integrateTagManager ( id ) {
                const headScript = document.createElement('script'),
                    bodyScript = document.createElement('noscript');

                headScript.text = `<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${ id }');
<!-- End Google Tag Manager -->`;

                bodyScript.innerHTML = `<!-- Google Tag Manager (noscript) -->
<iframe src="https://www.googletagmanager.com/ns.html?id=${ id }"
height="0" width="0"></iframe>
<!-- End Google Tag Manager (noscript) -->`;

                document.head.appendChild( headScript );
                document.body.appendChild( bodyScript );
            },
            injectCodeSnippet(code, type){
                try {
                    switch(type || "html"){
                        case "html": {
                            document.head.insertAdjacentHTML("beforeend", `<!-- Storyscale inject --> ${code} <!-- Storyscale inject -->`)
                            
                            break;
                        }
                        case "javascript": {
                            try {
                                new Function(code);
                            } catch (error) {
                                console.log('[Storyscale inject]: ', error.message);

                                break;
                            }

                            const scriptTag = document.createElement('script');

                            scriptTag.text = `try{${code}}}catch(e){console.log('[Storyscale inject]: ', e.message)}`

                            scriptTag.setAttribute("name", "storyscale-inject");

                            document.head.appendChild(scriptTag);

                            break;
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            setQueryParameter(name, value) {
                try {
                    const params = new URLSearchParams(window.location.search);
    
                    params.set(name, value);
    
                    window.history.replaceState({
                        [name]: value
                    }, `${name}:${value}`, decodeURIComponent(`${window.location.pathname}?${params}`));
                } catch (error) {
                    console.log(error);
                }
            },
            encodeBase64 ( data ) {
                return base64.encode( JSON.stringify( data ) );
            },
            decodeBase64 ( data ) {
                return base64.decode( data );
            },
            getReadableArticle(url) {
                return url ? fetch(url)
                  .then((res) => res.text())
                  .then((html) => {
                    const parser = new DOMParser();

                    const doc = parser.parseFromString(html, 'text/html');

                    const article = isProbablyReaderable(doc)
                      ? new Readability(doc).parse()
                      : null;

                    return article;
                  })
                  .catch((err) => console.log(err)) : null;
            },
            getReadableArticleHtml(url){
                return this.getReadableArticle(`${process.env.VUE_APP_CORS_URL}${url}`).then((article) => {
                    let html = "";

                    if (article) {
                        html = `
                            <!DOCTYPE html>
                            <html>
                                <head>
                                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" crossorigin="anonymous"/>
                                    <style>
                                        .article-display img {
                                            display: flex;
                                            margin: 0px auto;
                                            max-width: 100%;
                                        }

                                        .article-display h1, h2, h3, h4, h5, h6 {
                                            margin-top: 15px !important;
                                            margin-bottom: 15px !important;
                                        }
                                    </style>
                                </head>
                                <body>
                                    <section class="article-display p-2">
                                        <div><h3>${article?.title}</h5><hr/></div>
                                        <article>${article?.content}</article>
                                    </section>
                                </body>
                            </html>
                        `;
                    }else{
                        html = `
                            <!DOCTYPE html>
                            <html>
                                <head>
                                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" crossorigin="anonymous"/>
                                    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css" crossorigin="anonymous">
                                </head>
                                <body>
                                    <section class="d-flex align-items-center justify-content-center" style="height: 100vh">
                                       <div>
                                          <div class="text-center">
                                              <span class="text-warning" style="font-size: 90px">
                                                  <i class="bi bi-exclamation-triangle"></i>
                                              </span>
                                          </div>
                                          <div>
                                              <h6>Something went wrong!</h6>
                                          </div>
                                       </div>
                                    </section>
                                </body>
                            </html>
                        `;
                    }

                    return html;
                });
            },
        },
    });
}
